import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SImage = styled.div`
  grid-column-start: col-start 10;
  grid-column-end: col-start 12;

  display: flex;
  align-items: flex-end;

  margin: ${size(12)} 0;

  @media ${media.lte('tablet')} {
    display: none;
  }
`

export const SContent = styled.div`
  grid-column-start: col-1;
  grid-column-end: col-4;
  grid-row-start: 1;

  @media ${media.gt('mobile')} {
    grid-column-start: col-2;
    grid-column-end: col-3;
  }

  @media ${media.gt('tablet')} {
    grid-column-start: col-5;
    grid-column-end: col-8;
  }
`
