import styled from '@emotion/styled'
import { Layout } from 'components/layout/Layout'
import { size } from 'driverama-core/styles/spacing'
import { color, zIndex } from 'driverama-core/styles/variables'
import Logo from 'driverama-core/images/Logo.svg'

export const LoginLayout = styled(Layout)`
  grid-template-rows: ${size(22)} 1fr;
`

export const LoginHeader = styled.header`
  z-index: calc(${zIndex('sticky')});

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 ${size(10)};

  background-color: ${color('white')};
`

export const LoginHeaderLogo = styled(Logo)`
  color: ${color('night-l-100')};
`
