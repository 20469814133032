import { GetStaticProps } from 'next'
import { getIntlProps } from 'utils/intl'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextHeader } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { LoginContent } from 'sections/login/content/LoginContent'
import { LoginForm } from 'sections/login/form/LoginForm'
import {
  LoginHeader,
  LoginHeaderLogo,
  LoginLayout
} from 'sections/login/layout/LoginLayout'
import { LINKS } from 'constants/links'
import Link from 'next/link'
import { LoginFormOpenId } from '../sections/login/form/LoginFormOpenId'

export default function Page() {
  const { t } = useTranslation(['login'])

  return (
    <LoginLayout>
      <LoginHeader>
        <Link href={LINKS.home}>
          <a>
            <LoginHeaderLogo />
          </a>
        </Link>
      </LoginHeader>

      <LoginContent>
        <Spacer axis="vertical" size={12} />

        <TextHeader variant="h2" as="h2">
          {t('login:title')}
        </TextHeader>

        <Spacer axis="vertical" size={12} />

        {driverama.flags.IS_KEYCLOAK_ENABLED ? (
          <LoginFormOpenId />
        ) : (
          <LoginForm />
        )}
      </LoginContent>
    </LoginLayout>
  )
}

export const getStaticProps: GetStaticProps = async ctx => ({
  props: await getIntlProps(ctx, ['login'])
})
